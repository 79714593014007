import jwtDecode from 'jwt-decode'
import { AuthDetails } from '@crystal-eyes/types'

export default function getAuth(): Promise<AuthDetails> {
  if (process.env.RUNTIME_ENV === 'server') {
    return import('@crystal-eyes/components/contexts/AuthContext').then(
      async ({ isLoggedIn, authToken }) => {
        const token = await authToken()

        return {
          authed: await isLoggedIn(),
          authToken: token,
          sessionToken: 'no-session-token',
          jwtPayload: token ? (jwtDecode(token) as any) : null,
          appName: 'auth-api-login',
        }
      },
    )
  } else {
    return import('@crystal-eyes/components/contexts/AuthContextClient').then(
      async ({ isLoggedIn, getAuthToken }) => {
        const token = getAuthToken()

        return {
          authed: isLoggedIn(),
          authToken: token,
          sessionToken: 'no-session-token',
          jwtPayload: token ? (jwtDecode(token) as any) : null,
          appName: 'auth-api-login',
        }
      },
    )
  }
}
